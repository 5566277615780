import React from "react";
import styled from "styled-components";
import { FaCode } from "react-icons/fa";
import { FaExchangeAlt } from "react-icons/fa";
import { FaClock } from "react-icons/fa";
import { FaFilter } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start; /* Align to the left */
  padding: 20px;
  margin-top: 20px;
`;

const ToolCard = styled.div`
  display: flex;
  align-items: center;
  width: 300px;  /* Set a fixed width */
  background: #f4f4f4;
  padding: 15px;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
  text-align: left; /* Align text properly */

  &:hover {
    background: #ddd;
  }
`;

const IconContainer = styled.div`
  font-size: 30px;
  color: #333;
  margin-right: 15px; /* Space between icon and text */
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ToolName = styled.h3`
  margin: 0;
  font-size: 18px;
`;

const ToolDescription = styled.p`
  margin: 5px 0 0;
  font-size: 14px;
  color: #666;
`;

const HomePage = () => {
  const navigate = useNavigate();
  const tools = [
    { 
      name: "JSON Formatter", 
      icon: <FaCode />, 
      description: "Format and validate JSON data easily.",
      route: "/json-formatter" 
    },
    { 
      name: "Comma Quote Tool", 
      icon: <FaExchangeAlt />, 
      description: "Convert lists to CSV and CSV to lists easily.", 
      route: "/comma-quote-tool" 
    },
    { 
      name: "Timestamp Converter", 
      icon: <FaClock />, 
      description: "Convert timestamps to human-readable dates and vice versa.", 
      route: "/timestamp-converter" 
    },
    { 
      name: "Duplicate Filter", 
      icon: <FaFilter />, 
      description: "Remove duplicate entries from your list.", 
      route: "/duplicate-filter" 
    }           
  ];

  return (
    <Container>
      {tools.map((tool, index) => (
        <ToolCard key={index} onClick={() => navigate(tool.route)}>
          <IconContainer>{tool.icon}</IconContainer>
          <TextContainer>
            <ToolName>{tool.name}</ToolName>
            <ToolDescription>{tool.description}</ToolDescription>
          </TextContainer>
        </ToolCard>
      ))}
    </Container>
  );
};

export default HomePage;
