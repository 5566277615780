import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export default function TimestampTool() {
  const [currentTimestamp, setCurrentTimestamp] = useState(dayjs().unix());
  const [currentTime, setCurrentTime] = useState(dayjs().format("YYYY-MM-DD HH:mm:ss"));
  const [inputDate, setInputDate] = useState("");
  const [inputTimestamp, setInputTimestamp] = useState("");
  const [convertedTimestamp, setConvertedTimestamp] = useState(null);
  const [convertedDate, setConvertedDate] = useState(null);
  const [selectedTimezone, setSelectedTimezone] = useState("UTC");

  // List of timezones with offsets
  const timezones = [
    "UTC",
    "America/New_York",
    "Europe/London",
    "Asia/Kolkata",
    "Asia/Tokyo",
    "Australia/Sydney",
  ];

  // Function to format timezones with offset
  const getFormattedTimezone = (tz) => {
    const offset = dayjs().tz(tz).format("Z"); // Get timezone offset (e.g., "+5:30")
    return `${tz} (UTC${offset})`;
  };

  // Update live timestamp every second
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTimestamp(dayjs().unix());
      setCurrentTime(dayjs().tz(selectedTimezone).format("YYYY-MM-DD HH:mm:ss"));
    }, 1000);
    return () => clearInterval(interval);
  }, [selectedTimezone]);

  // Convert Date → Timestamp
  const handleConvertToTimestamp = () => {
    const date = dayjs.tz(inputDate, selectedTimezone);
    if (date.isValid()) {
      setConvertedTimestamp(date.unix());
    } else {
      setConvertedTimestamp("Invalid Date");
    }
  };

  // Convert Timestamp → Date
  const handleConvertToDate = () => {
    const timestamp = parseInt(inputTimestamp);
    if (!isNaN(timestamp)) {
      setConvertedDate(dayjs.unix(timestamp).tz(selectedTimezone).format("YYYY-MM-DD HH:mm:ss"));
    } else {
      setConvertedDate("Invalid Timestamp");
    }
  };

  return (
    <Box sx={{ textAlign: "center", mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Timestamp Converter
      </Typography>

      {/* Live Timestamp and Time */}
      <Typography variant="h6" color="primary">
        Current Unix Timestamp: {currentTimestamp}
      </Typography>
      <Typography variant="h6" color="primary">
        Current Time ({selectedTimezone}): {currentTime}
      </Typography>

      {/* Timezone Selection */}
      <FormControl fullWidth sx={{ mt: 3, maxWidth: 300, mx: "auto" }}>
        <InputLabel id="timezone-label">Select Timezone</InputLabel>
        <Select
          labelId="timezone-label"
          value={selectedTimezone}
          onChange={(e) => setSelectedTimezone(e.target.value)}
        >
          {timezones.map((tz) => (
            <MenuItem key={tz} value={tz}>
              {getFormattedTimezone(tz)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Paper elevation={3} sx={{ p: 3, mt: 3, maxWidth: 500, mx: "auto" }}>
        {/* Convert Date to Timestamp */}
        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1">Convert Date to Timestamp</Typography>
          <TextField
            fullWidth
            label="Enter Date (YYYY-MM-DD HH:mm:ss)"
            variant="outlined"
            value={inputDate}
            onChange={(e) => setInputDate(e.target.value)}
            sx={{ mt: 1 }}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
            onClick={handleConvertToTimestamp}
          >
            Convert to Timestamp
          </Button>
          {convertedTimestamp !== null && (
            <Typography sx={{ mt: 2 }}>Timestamp: {convertedTimestamp}</Typography>
          )}
        </Box>

        {/* Convert Timestamp to Date */}
        <Box>
          <Typography variant="subtitle1">Convert Timestamp to Date</Typography>
          <TextField
            fullWidth
            label="Enter Unix Timestamp"
            variant="outlined"
            value={inputTimestamp}
            onChange={(e) => setInputTimestamp(e.target.value)}
            sx={{ mt: 1 }}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
            onClick={handleConvertToDate}
          >
            Convert to Date
          </Button>
          {convertedDate !== null && (
            <Typography sx={{ mt: 2 }}>Date: {convertedDate}</Typography>
          )}
        </Box>
      </Paper>
    </Box>
  );
}
