// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB410xGIIqlM7DE4MP0fD9D0TQ-6foz-ys",
  authDomain: "devkit-22e83.firebaseapp.com",
  projectId: "devkit-22e83",
  storageBucket: "devkit-22e83.firebasestorage.app",
  messagingSenderId: "258250846119",
  appId: "1:258250846119:web:9c914b783115fb31e35082",
  measurementId: "G-PFK1PFEJ0Z"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { app, analytics };