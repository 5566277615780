import React, { useState } from "react";
import "./FindReplace.css";

const FindReplace = () => {
  const [text, setText] = useState("");
  const [find, setFind] = useState("");
  const [replace, setReplace] = useState("");

  const handleReplace = () => {
    if (find) {
      const regex = new RegExp(find, "g");
      setText(text.replace(regex, replace));
    }
  };

  return (
    <div className="find-replace-container">
      <h1>Find & Replace Tool</h1>
      <textarea
        className="text-input"
        rows="6"
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <div className="controls">
        <input
          type="text"
          placeholder="Find"
          value={find}
          onChange={(e) => setFind(e.target.value)}
        />
        <input
          type="text"
          placeholder="Replace with"
          value={replace}
          onChange={(e) => setReplace(e.target.value)}
        />
        <button onClick={handleReplace}>Replace</button>
      </div>
    </div>
  );
};

export default FindReplace;
