import React, { useState, useEffect } from "react";
import Editor from "@monaco-editor/react";
import "./JsonFormatter.css";

const JsonFormatter = () => {
  const [jsonInput, setJsonInput] = useState('{"name": "John Doe", "age": 30, "city": "New York"}');
  const [formattedJson, setFormattedJson] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    try {
      const parsed = JSON.parse(jsonInput);
      setFormattedJson(JSON.stringify(parsed, null, 2));
      setError("");
    } catch (err) {
      setError("Invalid JSON");
    }
  }, [jsonInput]);

  return (
    <div className="json-formatter-container">
      {/* <h2>JSON Formatter</h2>
      Paste or edit your JSON, and it will be automatically formatted. */}
      <div className="editor-container">
        <Editor
          height="80vh"
          language="json"
          value={jsonInput}
          onChange={(val) => setJsonInput(val)}
          theme="vs-dark"
        />
        <Editor
          height="80vh"
          language="json"
          value={formattedJson}
          options={{ readOnly: true }}
          theme="vs-dark"
        />
      </div>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default JsonFormatter;
