import React, { useState } from "react";
import { Container, TextField, Button, Typography, ToggleButtonGroup, ToggleButton, Box, Grid, IconButton, Tooltip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const CommaQuoteTool = () => {
  const [inputText, setInputText] = useState("");
  const [outputText, setOutputText] = useState("");
  const [quoteType, setQuoteType] = useState('"'); // Default: Double Quotes
  const [copySuccess, setCopySuccess] = useState(false);

  const handleConvertToQuoted = () => {
    const lines = inputText.split("\n").map((line) => line.trim()).filter((line) => line !== "");
    const quoted = lines.map((word) => `${quoteType}${word}${quoteType}`).join(", ");
    setOutputText(quoted);
  };

  const handleConvertToList = () => {
    const words = inputText.split(/,\s*/).map((word) => word.replace(/^['"]|['"]$/g, "").trim());
    setOutputText(words.join("\n"));
  };

  const handleConvertToCommaSeparated = () => {
    const lines = inputText.split("\n").map((line) => line.trim()).filter((line) => line !== "");
    setOutputText(lines.join(", "));
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(outputText);
    setCopySuccess(true);
    setTimeout(() => setCopySuccess(false), 2000);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 5, mb: 5, py: 2 }}>
      <Typography variant="h4" gutterBottom align="center" sx={{ mb: 3 }}>Comma Quote Converter</Typography>

      <Grid container spacing={4}>
        {/* Top Section: Tool Functionality */}
        <Grid item xs={12}>
          <TextField 
            label="Enter your text here" 
            multiline 
            fullWidth 
            rows={6} 
            variant="outlined" 
            value={inputText} 
            onChange={(e) => setInputText(e.target.value)}
            sx={{ mb: 4 }} 
          />

          <Typography variant="body1" gutterBottom sx={{ mb: 2 }}>Choose Quote Type:</Typography>
          <ToggleButtonGroup
            value={quoteType}
            exclusive
            onChange={(e, newQuote) => newQuote && setQuoteType(newQuote)}
            sx={{ mb: 4 }}
            size="large"
          >
            <ToggleButton value='"'>Double Quotes (")</ToggleButton>
            <ToggleButton value="'">Single Quotes (')</ToggleButton>
          </ToggleButtonGroup>

          <Box sx={{ display: "flex", gap: 3, mb: 4, flexWrap: "wrap" }}>
            <Button variant="contained" color="primary" onClick={handleConvertToQuoted}>
              Convert to Quoted
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleConvertToList}>
              Convert to List
            </Button>
            <Button variant="contained" color="success" onClick={handleConvertToCommaSeparated}>
              Convert to Comma-Separated (No Quotes)
            </Button>
          </Box>

          <Box sx={{ position: "relative" }}>
            <TextField 
              label="Output" 
              multiline 
              fullWidth 
              rows={6} 
              variant="outlined" 
              value={outputText} 
              sx={{ bgcolor: "#f5f5f5", borderRadius: 1, mb: 2 }} 
              InputProps={{ readOnly: true }} 
            />
            {outputText && (
              <Tooltip title={copySuccess ? "Copied!" : "Copy to clipboard"} placement="top">
                <IconButton 
                  onClick={handleCopyToClipboard}
                  sx={{ 
                    position: "absolute", 
                    top: "8px", 
                    right: "8px",
                    bgcolor: copySuccess ? "success.light" : "grey.200",
                    "&:hover": { bgcolor: copySuccess ? "success.main" : "grey.300" }
                  }}
                  size="small"
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Grid>

        {/* Bottom Section: How-To Guide */}
        <Grid item xs={12}>
          <Box sx={{ p: 5, mt: 4, bgcolor: "#eef", borderRadius: 2, boxShadow: "0 2px 8px rgba(0,0,0,0.1)" }}>
            <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>How to Use This Tool?</Typography>

            <Typography variant="body1" gutterBottom>
              <strong>🔹 Convert List to Quoted Format</strong><br />
              If you have a list of words or phrases and need them in <strong>comma-separated format</strong> with <strong>quotes</strong>, use this option.
            </Typography>
            <Box sx={{ bgcolor: "#ddd", p: 3, borderRadius: 1, fontFamily: "monospace", my: 2 }}>
              <strong>Example Input:</strong><br />
              Apple<br />
              Banana<br />
              Mango<br /><br />
              <strong>Output (if using double quotes):</strong><br />
              "Apple", "Banana", "Mango"
            </Box>

            <Typography variant="body1" mt={3} gutterBottom>
              <strong>🔹 Convert Quoted Format to List</strong><br />
              If you have a <strong>quoted, comma-separated</strong> format and want it as a <strong>clean list</strong>, use this option.
            </Typography>
            <Box sx={{ bgcolor: "#ddd", p: 3, borderRadius: 1, fontFamily: "monospace", my: 2 }}>
              <strong>Example Input:</strong><br />
              "Apple", "Banana", "Mango"<br /><br />
              <strong>Output:</strong><br />
              Apple<br />
              Banana<br />
              Mango
            </Box>

            <Typography variant="body1" mt={3} gutterBottom>
              <strong>🔹 Convert List to Comma-Separated (No Quotes)</strong><br />
              If you want a <strong>comma-separated</strong> format without any quotes, use this option.
            </Typography>
            <Box sx={{ bgcolor: "#ddd", p: 3, borderRadius: 1, fontFamily: "monospace", my: 2 }}>
              <strong>Example Input:</strong><br />
              Apple<br />
              Banana<br />
              Mango<br /><br />
              <strong>Output:</strong><br />
              Apple, Banana, Mango
            </Box>

            <Typography variant="body1" mt={3}>
              <strong>⚠️ Notes:</strong><br />
              - Ensure your input is correctly formatted to avoid errors.<br />
              - The tool automatically removes extra spaces and empty lines.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CommaQuoteTool;
