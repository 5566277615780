import React, { useState } from "react";
import { Box, TextField, Button, Typography, Paper } from "@mui/material";

export default function DuplicateFilter() {
  const [inputText, setInputText] = useState("");
  const [filteredText, setFilteredText] = useState("");

  // Function to remove duplicates
  const handleFilterDuplicates = () => {
    const lines = inputText.split("\n").map(line => line.trim()).filter(line => line !== "");
    const uniqueLines = [...new Set(lines)];
    setFilteredText(uniqueLines.join("\n"));
  };

  // Function to copy output
  const handleCopy = () => {
    navigator.clipboard.writeText(filteredText);
  };

  return (
    <Box sx={{ maxWidth: 600, mx: "auto", mt: 5, textAlign: "center" }}>
      <Typography variant="h4" gutterBottom>
        Duplicate Filter
      </Typography>

      <Paper elevation={3} sx={{ p: 3 }}>
        {/* Input Text Area */}
        <TextField
          label="Enter or Paste Text (one item per line)"
          multiline
          rows={6}
          fullWidth
          variant="outlined"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          sx={{ mb: 2 }}
        />

        {/* Buttons */}
        <Button variant="contained" color="primary" onClick={handleFilterDuplicates} fullWidth sx={{ mb: 2 }}>
          Remove Duplicates
        </Button>

        {/* Output Text Area */}
        {filteredText && (
          <>
            <TextField
              label="Filtered Output"
              multiline
              rows={6}
              fullWidth
              variant="outlined"
              value={filteredText}
              InputProps={{ readOnly: true }}
              sx={{ mb: 2 }}
            />
            <Button variant="contained" color="secondary" onClick={handleCopy} fullWidth>
              Copy to Clipboard
            </Button>
          </>
        )}
      </Paper>
    </Box>
  );
}
