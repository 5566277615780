import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./components/HomePage";
import FindReplace from "./components/FindReplace";
import CommaQuoteTool from "./components/CommaQuoteTool";
import JsonFormatter from "./components/JsonFormatter";
import EpochTool from "./components/TimestampTool";
import DuplicateFilterTool from "./components/DuplicateFilterTool";
import styled from "styled-components";
import { useEffect } from "react";
import { analytics } from "./firebase";
import { logEvent } from "firebase/analytics";
import { useLocation } from "react-router-dom";



const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full screen height */
`;

const Header = styled.header`
  background: #333;
  color: white;
  padding: 30px;
  font-size: 20px;
  font-weight: bold;
  width: 100vw; /* Full width */
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  z-index: 1000; /* Keeps it on top */
`;

const Content = styled.main`
  flex: 1;
  padding: 10px 20px 20px; /* Ensure enough top padding */
  margin-top: 60px; /* Adjust based on your header height */
`;


const Footer = styled.footer`
  background: #333;
  color: white;
  padding: 10px;
  text-align: center;
  width: 100vw; /* Ensures full width */
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 0;
  z-index: 1000;
`;

const PageTracker = () => {
  const location = useLocation();

  useEffect(() => {
    logEvent(analytics, "page_view", { page_path: location.pathname });
  }, [location]);

  return null;
};

function App() {
  return (
    <Router>
      <PageTracker /> {/* Logs page views on route changes */}
      <PageContainer>
        <Header>DevKit Tools</Header>
        <Content>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/json-formatter" element={<JsonFormatter />} />
            <Route path="/find-replace" element={<FindReplace />} />
            <Route path="/comma-quote-tool" element={<CommaQuoteTool />} />
            <Route path="/timestamp-converter" element={<EpochTool />} />
            <Route path="/duplicate-filter" element={<DuplicateFilterTool />} />
          </Routes>
        </Content>
        <Footer>© 2025 DevKit</Footer>
      </PageContainer>
    </Router>
  );
}

export default App;
